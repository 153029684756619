import React, { useState } from 'react';
import { navigate } from 'gatsby';
import axios from 'axios';
import '../../assets/styles/components/search-form.scss';

/* global window */
const SearchForm = ({ searchTitle, placeholderCopy }) => {
  const [hideSuggestions, setHideSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchSuburbPostcode = (event) => {
    const { value } = event.target;
    setLoading(true);
    axios.post(`${process.env.GATSBY_PORT}endpoints/postcodeseosearch`,
      { 'keyword': value, 'url': window.location.href }
    ).then(res => {
      const { data } = res;
      if (data.success && data.data !== null) {
        setSuggestions(data.data);
      } else {
        setSuggestions([]);
      }
      setLoading(false);
    });
  };

  const chooseSuburb = (e, url) => {
    e.preventDefault();
    setHideSuggestions(true);
    navigate(url);
  };

  return (
    <section className="search-form-wrapper">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-5 col-lg-5">
            <div className="card">
              <div className="card-body text-center">
                <h4>{searchTitle}</h4>

                <form className="form-inline justify-content-center" onSubmit={(e) => { e.preventDefault() }}>
                  <div className="form-group form-group-suggestion">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="zip"
                      name="zip"
                      placeholder={placeholderCopy}
                      onChange={(e) => { handleInputChange(e); }}
                      onChange={(e) => { fetchSuburbPostcode(e); }}
                      onFocus={() => { setHideSuggestions(false); }}
                      onBlur={() => { setHideSuggestions(true); }}
                    />
                    {loading && (
                      <div className="lds-ring">
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                    )}
                    <span className="icon-search" />
                    {suggestions && suggestions.length > 0 && (
                      <ul className={`suggestion-wrapper ${hideSuggestions ? 'hide' : ''}`}>
                        {suggestions.map((suggestion, idx) => (
                          <li key={`${suggestion.list}-${idx}`} onClick={(e) => { chooseSuburb(e, suggestion.url) }}>
                            {suggestion.list}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchForm;
